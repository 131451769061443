import axios from 'axios';
import LiveEvent from '../../../components/live/live';
import Modal from '../../../components/modal/modal';
import SaveForm from './saveForm';
import SlotsPage from '../index';

class SlotTable {
  constructor(container) {
    this.container = container;
    this.createUrl = '/slots/create';
    this.editUrl = '/slots/edit';
    this.aproveUrl = '/slots/approve';

    this.bind();
  }

  bind() {
    this.bindCreateLinks();
    this.bindTimeList();
    this.bindApproveLink();
  }

  bindCreateLinks() {
    const instance = this;
    new LiveEvent('click', '[data-create-slot],[data-edit-slot]', function (e) {
      e.preventDefault();
      const link = this;
      const slotId = link.dataset.editSlot;
      if (slotId) {
        instance.editModalById(slotId);
      } else {
        instance.createModalByLink(link);
      }
    });
  }

  bindApproveLink() {
    const instance = this;
    new LiveEvent('click', '[data-approve]', (async (e) => {

      const cellContent = e.target.closest('.slots-cell__content');
      const slotId = cellContent.querySelector('[data-edit-slot]').dataset.editSlot;
      if (slotId) {
        const url = `${this.aproveUrl}/${slotId}`;

        const response = await instance.asyncRequest(url);
        if (response.data.success === true) {
          let color = '_color_40';
          if (response.data.isPartner === true) {
            color = '_color_60';
          }
          const regex = /_color_[0-9]+/i;
          cellContent.innerHTML = cellContent.innerHTML.replace(regex, color);
        }
      }
    }));
  }

  bindTimeList() {
    const instance = this;
    new LiveEvent('change', '[data-time-list-form] input[type="checkbox"]', (e) => {
      instance.timeListEvent(e.target);
    });
  }

  async timeListEvent(checkboxElement) {
    const time = checkboxElement.value;
    const isChecked = checkboxElement.checked;
    const dayWrap = checkboxElement.closest('[data-row-day]');
    const day = dayWrap.dataset.rowDay;
    let timeWrap = dayWrap.querySelector(`[data-row-time="${time}"]`);
    if (!timeWrap) {
      timeWrap = await this.fetchTimeWrap({ day, time });
      let paste = false;
      const currentTimes = Array.from(dayWrap.querySelectorAll('[data-row-time]'));
      currentTimes.forEach((row) => {
        if (!paste && row.dataset.rowTime > time) {
          row.before(timeWrap);
          paste = true;
        }
      });
      if (!paste) {
        currentTimes.pop().after(timeWrap);
      }
    }

    if (timeWrap) {
      if (isChecked) {
        timeWrap.classList.remove('_hidden');
      } else {
        timeWrap.classList.add('_hidden');
      }
    }
  }

  /** TODO try catch */
  async fetchTimeWrap(data) {
    data.category_id = SlotsPage.categoryId;
    const response = await this.asyncRequest('/slots/time-row', data);

    const html = document.createElement('div');
    html.innerHTML = response.data;

    return html.querySelector('[data-row-time]');
  }

  async asyncRequest(url, data) {
    return axios.post(url, data);
  }

  editModalById(slotId) {
    const url = `${this.editUrl}/${slotId}`;
    this.request(url);
  }

  createModalByLink(link) {
    const hasTime = link.closest('[data-row-time]');
    const hasDay = link.closest('[data-row-day]');
    const hasGroupId = link.closest('[data-group-id]');

    const data = {
      time: hasTime.dataset.rowTime,
      day: hasDay.dataset.rowDay,
      group_id: hasGroupId.dataset.groupId,
    };

    this.request(this.createUrl, data);
  }

  request(url, data) {
    const response = axios.post(url, data);
    response
      .then((result) => {
        const element = document.createElement('div');
        element.innerHTML = result.data;

        const modal = new Modal(element, {
          preloader: false,
          closerText: '',
          useAjaxForm: true,
        });

        new SaveForm(modal);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  findAndReplaceCell(cellInfo) {
    const column = this.container.querySelector(`[data-column-group="${cellInfo.group_id}"]`);
    if (!column) {
      return;
    }

    const dayRow = this.container.querySelector(`[data-row-day="${cellInfo.day}"]`);
    if (!dayRow) {
      return;
    }

    const timeWrap = dayRow.querySelector(`[data-row-time="${cellInfo.time}"]`);
    if (timeWrap) {
      const cell = timeWrap.querySelector(`[data-group-id="${cellInfo.group_id}"]`);
      if (cell) {
        const content = document.createElement('div');
        content.innerHTML = cellInfo.html;
        cell.innerHTML = content.innerHTML;
      }
    }

    const checkbox = dayRow.querySelector(`[data-time-list-form] [data-input][value="${cellInfo.time}"]`);

    if (checkbox && !checkbox.checked) {
      checkbox.checked = 'checked';
      checkbox.dispatchEvent(new Event('change'));
    }
  }

  showAllGroups() {
    this.container.querySelectorAll('[data-column-group]._hide').forEach((element) => {
      element.classList.remove('_hide');
    });
    this.container.querySelectorAll('[data-row-time] [data-group-id]._hide').forEach((element) => {
      element.classList.remove('_hide');
    });
  }

  showGroup(groupId) {
    this.container.querySelectorAll(`[data-column-group="${groupId}"]._hide`).forEach((element) => {
      element.classList.remove('_hide');
    });
    this.container.querySelectorAll(`[data-row-time] [data-group-id="${groupId}"]._hide`).forEach((element) => {
      element.classList.remove('_hide');
    });
  }

  hideGroup(groupId) {
    this.container.querySelectorAll(`[data-column-group="${groupId}"]:not(._hide)`).forEach((element) => {
      element.classList.add('_hide');
    });
    this.container.querySelectorAll(`[data-row-time] [data-group-id="${groupId}"]:not(._hide)`).forEach((element) => {
      element.classList.add('_hide');
    });
  }
}

export default SlotTable;